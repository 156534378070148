.DemoWrapper {
    height: 100%;
    width: 100%;
    padding: 2rem 0;
    border-bottom: 2px solid #BBBBBB;
}

.statHolder {
    margin-top: 2rem;
    margin-top: 2rem;
    display: flex;
    gap: 5rem;
}

.stat {
    display: flex;
    flex-direction: column;
    justify-content: center;
   

}


.stat div:first-of-type {
    font-weight: bold;
    padding-bottom: 10px;
}

.stat:last-of-type {
    border-right: unset;
}

.preWrap {
    white-space: pre-wrap;
}