.CloseModal {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    background-color: transparent;
    border: 0;
}

.CloseModal:hover {
    cursor: pointer;
}

.CloseModal img {
    width: 20px;
}

.ContactModalStyles body.ContactForm__Body--open {
    overflow: hidden !important;
}

.successMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.successMessage p {
    text-align: center;
}

.ContactModalStyles dialog::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
}

.ContactForm__Content h2 {
    text-align: center;
    font-weight: 800;
    font-size: 36px;
    padding: 1rem 0;
}

.ContactForm__Content h3 {
    font-weight: normal;
    margin-top: 1rem;
}

.ContactForm__Content img {
    margin: auto;
}

.ContactForm__Content ul {
    justify-content: space-around;
}

.ContactForm__Content ul li:first-of-type a {
    border-radius: 10px 0 0 0;
}

.ContactForm__Content ul li:last-of-type a {
    border-radius: 0 10px 0 0;
}


.ContactForm__Content {
    position: relative;
    z-index: 10000;
    padding: 2em;
    width: 100%;
    max-width: 100%;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 15px;
    background-color: white;
    outline: none;

    @media (prefers-color-scheme: dark) {
        background-color: rgb(35, 43, 50);
    }

}

.ContactForm__Content form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;


}

.ContactForm__Content form textarea,
.ContactForm__Content form input {
    font-size: 16px;
}

.ContactForm__Content form label {
    margin: 0;
    margin-top: 0.75rem;
    font-size: 18px;
}

.ContactForm__Content form input[type="text"],
.ContactForm__Content form input[type="tel"],
.ContactForm__Content form input[type="email"],
.ContactForm__Content form select,
.ContactForm__Content form textarea {
    flex: 0 1 auto;
    margin-top: 0.25rem;

    width: 100%;
    border-radius: 15px;
    padding: 0.5rem;
    font-size: 18px;
    border: 1px solid rgb(235, 235, 235);
}

.ContactForm__Content form select {
    background-position: 97% !important;
}

.ContactForm__Content form textarea {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    border-radius: 15px;
    padding: .5rem;
    border: 1px solid rgb(235, 235, 235);

    @media (prefers-color-scheme: dark) {
        background-color: white;
        border: 1px solid white;
    }
}

.ContactForm__Content form textarea:focus {
    outline: none;
}

.ContactForm__Content form legend {
    font-size: 0.8rem;
    margin-top: 0.5rem;
}

.ContactForm__Content form span {
    margin-top: 1.25rem;
    align-self: center;
}

.ContactForm__Content form span input {
    font-size: 16px;
}

.ContactForm__Content form>div {
    margin-top: 1rem;
}

@media screen and (min-width: 768px) {
    .Property_contact {

        width: min-content !important;
        max-width: unset !important;
    }

    .nearMe {

        width: 420px !important;
    }

    .ContactForm__Content {
        inset: 0;
        width: 420px;
        max-width: 420px;
    }
}

@media screen and (max-width: 768px) {
    .ContactForm__Content form {
        align-items: flex-start;
    }
}

@media screen and (max-width: 360px) {
    .ContactForm__Content div,
    .ContactForm__Content label,
    .ContactForm__Content select {
        font-size: 15px !important;
    }
}