.TermsHeader {
    text-align: center;
    padding: 2em 0;
}

.TermsHeader h1 {
    font-size: 66px;
}

.TermsHeader h3 {
    font-size: 33px;
}

.TermsBody {
    padding-left: 3rem;
    margin-bottom: 4rem;
}

.TermsBody>* {
    position: relative;
    margin: 0;
}

.TermsBody h2 {
    margin-top: 1.5rem;
}

.TermsBody h3 {
    margin-top: 0.9rem;
}

.TermsBody h4 {
    margin-top: 0.8rem;
}

.TermsBody h5 {
    margin-top: 0.7rem;
}

.TermsBody h6 {
    margin-top: 0.6rem;
}

.TermsBody p {
    margin-top: 0.5rem;
}

.TermsBody a {
    text-decoration: underline;
}

.TermNumber {
    position: absolute;
    left: -2.5rem;
    display: inline-block;
}

@media screen and (max-width: 600px) {
    .TermsHeader h1 {
        font-size: 40px;
    }

    .TermsHeader h3 {
        font-size: 25px;
    }
}