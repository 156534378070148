.AgentContainer {
    text-align: center;
    padding-top: 3rem !important;
    width: 100%;
    max-width: 1240px;
    padding-right: 15px !important;
    padding-left: 15px !important;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 5rem !important;
}

.marginTop {
    margin-top: 5rem;
}

.contactForm {
    background-color: #F5F5F5;
    padding: 2rem;
    border-radius: 0.5px;
    border-radius: 10px;
    margin: 5rem 0 0 0;

}

.contactForm .input {
    background-color: white;
}

.contactForm form {
    margin-bottom: 0px;
}

.VidoeContainer {
    width: 100%;
    margin: auto;
    display: grid;
    margin-top: 5rem;
    padding: 0 1rem;
}

.VidoeContainer >div {
    position: relative;
    padding-top: 56.25%;
    margin-bottom: 20px;
}

.video {
    position: absolute;
    top: 0px;
    left: 0px;
}

.errorContanier {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    width: 100%;
    text-align: left;
}
.contact {
    background-color: #F5F5F5;
    border-radius: 10px;
    margin: 5rem 0;
    padding-bottom: 3rem !important;
}
.contact p {
    display: flex;
    display: flex;
    justify-content: center;
}

.contact svg {
    margin-right: 5px;
}

.singleListingBid h2 {
    margin-top: 3rem;
}

.SingleListing {
    margin-top: 2rem !important;
}

.AgentContainer h2 {
    font-size: 1.7em;
}

.AgentContainer h1 {
    font-size: 1.7em;
}

.AgentContainer h3 {
    font-size: 1.2em;
}

.AgentContainer p {
    font-size: 1em;
    line-height: 1.5;
}

.middlePara {
    margin-top: 5rem;
}

.topPara {
    max-width: 820px;
    margin-left: auto;
    margin-right: auto;
}

.mainPara {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
}

.contactButton:hover {
    background-color: #F73261;
}

.contactButton {
    background-color: #941E3A;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 500ms;
    height: fit-content;



}

.contactButton a {
    color: white;
}

.Bespoke {
    background-color: #F0F4FB;
}

.Bespoke p {
    text-align: center !important;
    padding-bottom: 1rem;
}

.SingleListing {
    background-color: white;
}

.SingleListing p {
    width: 75%;
    margin: auto;
}

.PopularPricing {
    font-size: 1.3rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.displayNone {
    display: none;
}

.PricingHeader {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
}

.divider {
    border-top: solid 1px #4474CA !important;
    width: 100% auto;
    margin: 1rem 0rem;
}

.PromiseList li {
    color:  #4474CA !important;
}
.PromiseList {
    background-color: white;
}

.PromiseList li:last-of-type {
    margin-bottom: 0;
}

.PromiseList {
    border: solid 1px #4474CA;
    border-radius: 5px;
    padding: 2rem;
    margin: 2rem auto;
}

.SingleListing,
.Bespoke {
    border: solid 1px #4474CA;
    border-radius: 5px;
    padding: 1rem;
    color: #4474CA;
    margin-right: auto;
    margin-left: auto;
    margin-top: 3rem;
    margin-bottom: 1rem;

}

.SingleListing p,
.Bespoke p {
    margin-bottom: 0;
    color: black;
    text-align: left;
    font-size: 14px;
}

.SingleListing ul,
.Bespoke ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    margin-bottom: 2rem;
}

.PromiseList ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.SingleListing li,
.PromiseList li,
.Bespoke li {
    display: grid;
    text-align: left;
    grid-template-columns: auto 1fr;
    grid-gap: 0.5rem;
    margin-bottom: 0.5rem;
    color: black;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    font-size: 14px;

    @media (min-width: 576px) {
        font-size:
    }

}


.SingleListing li svg, .PromiseList li svg,
.Bespoke li svg {
    color: green;
}

.whyCompletely {
    background: #F0F0F0;
    padding: 2rem;
    border-radius: 5px;
    @media (prefers-color-scheme: dark) {
        background-color: rgb(29, 37, 44);
    }
}

.whyCompletely p {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
}

.greyContainer {
    background: #F0F0F0;
    padding: 2rem;
    border-radius: 5px;
    min-height: 389.641px;
    align-items: center;

    @media (prefers-color-scheme: dark) {
        background-color: rgb(29, 37, 44);
    }
}

.greyContainer img {
    float: right;
}

.normalContainer {
    padding: 2rem;
}

.TextImageContainer {
    margin-top: 3rem;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;

}

.textImage {
    flex-basis: 41.666667%;
    flex-grow: 0;
    max-width: 41.666667%;
}

.textContent {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    flex-basis: 41.666667%;
    flex-grow: 0;
    max-width: 41.666667%;
}



.textContent button {
    width: fit-content;
}

.TextImageContainer img {
    display: flex;
    border-radius: 5px;
}

.reviews h2 {
    flex: 0 0 100%;
    margin-bottom: 1rem;
}

.review {
    margin: 18px 0 16px 0;
}


.reviews {
    margin-top: 5rem;
    padding: 2rem;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

}

.reviews div {
    background: white;
    border: solid 1px #4474CA;
    padding: 1rem;
    border-radius: 5px;
    flex: 1 1 0px;

    @media (prefers-color-scheme: dark) {
        background-color: dimgray;
    }
}

.reviews img {
    display: flex;
    height: 40px;
}

.reviews p {
    font-size: 1rem;
    text-align: left;
}

.reviewer {
    color: #4474CA;
    font-weight: bold;
    margin-bottom: 0;
}

.reviewerDetail {
    color: #4474CA;
    margin-top: 4px;
}

.divider {
    border-top: solid 1px #BABABA;
    width: 100%auto;
    margin: 1rem 0rem;
}

.infoBox {
    background: #F0F0F0;
    padding: 1rem;
    border-radius: 5px;
    margin-top: 2rem;
    flex: 1 1 0px;

    @media (prefers-color-scheme: dark) {
        background-color: rgb(29, 37, 44);
    }

}

.infoBoxContainer {
    display: flex;
    gap: 50px;
    margin: 3rem 2rem 0 2rem;
}

.contactForm2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    gap: 1rem;
    row-gap: 1rem;
    max-width: 1000px;
    margin: 3rem auto 0 auto;

}

.contactForm2 label {
    flex: 100%;
    text-align: left;
    font-size: 16px;
    @media screen and (min-width: 900px) {
        flex: 40%;
    }

    @media (prefers-color-scheme: dark) {
        background-color: dimgray;
        color: white;
    }
}

.contactForm2 input,  .contactForm2 textarea {
    font-size: 16px;
}
.inputFullWidth {
    flex: 100% !important;
}

.input {
    flex: 100%;
    text-align: left;

    @media (prefers-color-scheme: dark) {
        background-color: dimgray;
        color: white;
    }
}

.sticky {
    position: fixed;
    z-index: 100;
    width: 100%
}

.HeaderContainer {
    background: #F0F0F0;
    text-align: center;
    display: flex;
    flex-direction: column;

    @media (prefers-color-scheme: dark) {
        background-color: rgb(45, 53, 58);
    }
}

.mobileNav {
    background: #4474CA;
    font-weight: 400;
    font-size: 18px;
    padding: 0.5rem;
    text-align: left;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mobileNav span {
    text-decoration: underline;
}

.mobileMenuOpenButton div {
    width: 35px;
    height: 5px;
    background-color: white;
    margin: 6px 0;
}

.mobileMenuCloseButton {
    color: white;
    font-weight: bold;
    font-size: 30px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.active a {
    color: white !important;
}

.sticky {
    position: fixed;
    z-index: 100;
    width: 100%
}

.canvas {
    display: inline;
    position: absolute;
    z-index: -1;
    width: 100%;
}

.NavBarSales {
    box-sizing: border-box;
    background-color: #4474CA;
    padding: 0.5rem;
}


.navLinks {
    margin: 1rem;

}

.navLinks a {
    font-weight: bold;

    @media (prefers-color-scheme: dark) {
        color: black;
    }
}

.backButton {
    position: absolute;
    top: 10px;
    left: 42px;
    color: #941E3A;
    cursor: pointer;
}

.backButton button {
    padding: 4px 15px !important;
    margin-right: 10px;
}

.backButton span {
    text-decoration: underline;
}

.checkout {
    position: relative;
    min-height: 1370.8px;
}

.checkout h1 {
    margin-top: 5rem;
}

@media screen and (min-width: 900px) {
    .middlePara {
        max-width: 75%;
        margin-left: auto;
        margin-right: auto;
    }

    .SingleListing,
    .Bespoke {
        width: 50%;
    }

    .contact {
        margin: 5rem 2rem;
        width: 95%;
    }

    .PromiseList {
        max-width: max-content;
    }

    .whyCompletely p {
    
    }

    .whyCompletely {
        margin: 0 2rem 0 2rem;
    }

    .greyContainer {
        flex-flow: row-reverse !important;
    }

    .review {
        min-height: 166.5px;
    }

    .navLinks a {
        font-size: 1rem;
    }

    .col3 {
        max-width: 33%;
    }

    .col2 {
        max-width: 50%;
    }

    .NavBarSales {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        flex-direction: row;
        justify-content: center;
    }

    .TextImageContainer img {
        max-width: 100%;
    }

    .input {
        flex: 40%;
    }

    .mobileNav {
        display: none;
    }

    .mobileMenuOpenButton {
        display: none;
    }

    .mobileMenuCloseButton {
        display: none;
    }
}

@media screen and (max-width: 900px) {
    .textImage {
        flex-basis: 100%;
        flex-grow: 0;
        max-width: 100%;
    }

    .textContent {
        padding-top: 1rem;
        flex-basis: 100%;
        flex-grow: 0;
        max-width: 100%;
    }

    .reviews {
        flex-direction: column;
    }

    .infoBoxContainer {
        flex-direction: column;
    }

    .hideMobile {
        display: none;
    }
}