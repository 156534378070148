.LinkTray {
    max-width: 600px;
    margin: 2em auto;
    padding-bottom: 1em;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid white;
}

.LinkTray a {
    color: white;
    font-size: 20px;
  }
.SignupButtons {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: 1fr;
    grid-template-columns: min-content;
    grid-gap: 1rem;
    justify-content: center;
    margin-top: 1rem;
}

.SignupButtons button,
.SignupButtons a {
  display: block;
  width: auto;
  margin: 0 auto;
}
 
.SignupWrapper {
    display: grid;
  grid-template-rows: min-content;
  grid-template-columns: minmax(200px, 600px);
  align-content: center;
  justify-content: center;
  min-height: calc(100vh - 70px);
  padding: 10px;
  background-image: url("/images/index/feature-background-large-latestproperties.jpg");
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    display: -ms-grid;
    -ms-grid-columns: 1fr 600px 1fr;
    -ms-grid-rows: 1fr min-content 1fr;
    height: calc(100vh - 70px);
  }
}

.SignupWrapper:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 60;
    height: 100%;
    width: 100%;
    background-image: none;
    background-color: rgba(116, 208, 198, 0.95);
    background-size: cover;
    background-repeat: no-repeat;
    pointer-events: none;
    z-index: 1;
  }
  .SignupWrapper h1 {
    font-weight: bold;
    font-size: 42px;
  }
  .SignupWrapper p {
    font-weight: 600;
    font-size: 20px;
  }

.SignupContainer {
    z-index: 2;
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    -ms-grid-column: 2;
    -ms-grid-row: 2;
  }
}

.Required {
    margin: 0 auto 1rem auto;
}