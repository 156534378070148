.PricingWrapper {
    display: flex;
    grid-template-columns: 1fr;
    gap: 1rem;
}

@media screen and (max-width: 900px) {
    .PricingWrapper {
        flex-direction: column;
    }
}

.SwitchContainer {
    width: max-content;
    border: 1px solid;
    padding: 5px;
    border: solid 1px #4474CA;
    border-radius: 5px;
    margin-bottom: 2rem;
    margin-top: 3rem;
    background: white;

    @media (max-width: 576px) {
        margin: 0 auto 2rem auto;
    }
}

.SwitchContainer button {
    background: none;
    border: none;
    padding: 8px;
    color: #4474CA;
}

.activeButton {
    background: #4474CA !important;
    color: white !important;
    border-radius: 5px !important;
}

.vatMessage {
    text-align: left;
    color: #4474CA;
}

.PricingContainer {
    border: solid 1px #4474CA;
    padding: 1rem;
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto 1fr auto;
    height: 470px;
    background-color: white;

    @media (min-width: 768px) {
        margin-top: 0px !important;
    }

}

@media (min-width: 576px) {
    .tableButton {
        position: absolute;
        bottom: 20px;
        left: auto;
        align-self: baseline;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        width: 90%;
    }
}

.PopularPricing {
    font-size: 1.3rem;
    text-align: center;
    color: white;
    font-weight: bold;
    background: #4474CA;
    border-radius: 5px 5px 0px 0px;
    height: 40px;
    position: absolute;
    top: -40px;
    left: -1px;
    right: -1px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #4474CA;
}

.PricingHeader {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: #4474CA;

}

.pricing {
    margin-bottom: 1rem;
    color: #4474CA;
    margin-top: 0;
    font-size: 1.3rem;


}

.pricing span {
    font-size: 13px;
}

.divider {
    border-top: solid 1px #4474CA;
    width: 100%;
    margin: 1rem 0rem;
}

.PricingContainer ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    margin-bottom: 2rem;
}

.PricingContainer button {
    background-color: #941E3A;
    color: white;
    border: none;
    padding: 1rem;

    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 500ms;
    height: fit-content;


}

.PricingContainer button:hover {
    background-color: #F73261;
}

.PricingContainer li {
    display: grid;
    text-align: left;
    grid-template-columns: auto 1fr;
    grid-gap: 0.5rem;
    margin-bottom: 0.5rem;

    font-size: 14.4px;

    @media (min-width: 576px) {
        font-size: 16px;
    }


}

.PricingContainer li p {
    margin-top: 2px;
    margin-bottom: 0;
}

.notIncluded {
    color: #A7A7A7;
}