.UnsponsoredBanner {
  width: 100%;
  background-color: #941E3A;
  opacity: 1;
  transition: opacity 500ms;
  padding-top: 5px;
  padding-bottom: 5px;
}

.bannerContent {
  height: 100%;
  cursor: pointer;
}

.unsponsoredWrapper {
  height: 100%;
  width: 100%;
  min-height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;

}

.nonSponsoredBannerText {
  padding: 20px;
}

.unsponsoredWrapper p {
  color: white;
  text-align: center;
  font-size: 14.4px;
}

.unsponsoredWrapper :hover p {
  text-decoration: underline;
}

.Banner {
  height: 100px;
  width: 100%;
  opacity: 1;
  transition: opacity 500ms;
}


.CompanyWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.CompanyWrapper :hover p {
  text-decoration: underline;
}

.CompanyWrapper p {
  color: white;
  font-weight: bold;
}


.logo {
  display: block;
  width: 175px;
  height: 175px;
  margin-right: 2em;
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
}

.filterBackground {
  filter: brightness(0) invert(1);
}

@media screen and (min-width: 768px) {
  .UnsponsoredBanner {
    height: 6rem;
  }

  .unsponsoredWrapper p {
    font-size: 22.6px;
  }

  .Banner {
    height: 120px;
  }
}