.Form {
    width: 100%;
}

.FormContainer {
    max-width: 600px;
    width: 100%;
    margin: auto;
    border-radius: 0;
    border: none;
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box;
    padding: 0;
    text-align: left;
}

.ShowContainer {
      border-radius: 20px;
    border:  10px solid rgba(255,255,255,0.3);
    background-color: rgba(255,255,255,0.3);
    -webkit-background-clip: padding-box; /* for Safari */
}

.FormContainer label {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 5px;
}
.input-wrapper {
    position: relative;
}

.input-wrapper div {
    width: 50px;
    height: 50px;
    background-color: #F6F6F6;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    border-radius: 10px 0px 0px 10px;
    border: 1px solid #ced4da;
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box;
}

.input-wrapper input {
    padding-left: 4em;
    height: 50px;
    border-radius: 10px;
}

.input-wrapper input:-webkit-autofill,
.input-wrapper input:-webkit-autofill:hover,
.input-wrapper input:-webkit-autofill:focus {
        /* -webkit-text-fill-color: black; */
        -webkit-box-shadow: 0 0 0px 1000px rgb(252, 252, 252) inset;
        transition: background-color 5000s ease-in-out 0s;
    }
    .input-wrapper input:focus {
        border-color: transparent;
        box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.25)
    }

.Label {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 5px;
    display: inline-block;
}

.HiddenLabel {
    position: absolute;
    top: auto;
    left: -9999px;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.FormGroup {
    margin-bottom: 1rem;
}

.FormGroupp, .FormGroup ul {
    margin: 0 0 .5rem 0;
    font-size: 16px;

}

.InputWrapper {
    position: relative;
}

.InputWrapper div {
    width: 50px;
    height: 50px;
    background-color: #F6F6F6;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    border-radius: 10px 0px 0px 10px;
    border: 1px solid #ced4da;
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box;
}

.InputWrapper input:-webkit-autofill,
.InputWrapper input:-webkit-autofill:hover,
.InputWrapper input:-webkit-autofill:focus {
    /* -webkit-text-fill-color: black; */
    -webkit-box-shadow: 0 0 0px 1000px rgb(252, 252, 252) inset;
    transition: background-color 5000s ease-in-out 0s;
}
.InputWrapper input:focus {
    border-color: transparent;
    box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.25)
}

.InputIcon {
    width: 50px;
    height: 50px;
    background-color: #F6F6F6;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    border-radius: 10px 0px 0px 10px;
    border: 1px solid #ced4da;
    background-clip: padding-box;
}

.Input {
    height: 50px;
    border-radius: 10px;
    display: block;
    width: 100%;
    /* padding: .375rem .75rem; */
  /*  padding: ${props => props.theme.icon ? '.375rem .75rem .375rem 4em': '.375rem .75rem'};*/
    font-size: 1rem;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: background-color 5000s ease-in-out 0s;
    /* -webkit-text-fill-color: #000; */
    color: #495057;
    background-color: #fff;
}

.Input:focus, .Input:active {
    outline: none;
    box-shadow: 0 0 3pt 1pt #EC7474;
}

.InputValidation p {
    margin: .5rem 0 0 0;
    font-size: 14px;
}

.CaptchaContainer {
    position: relative;
    margin-top: .5rem;
}

.CaptchaContainer div {
    display: inline-block;
}

.CaptchaInput {
    position: absolute;
    bottom: 30px;
    left: 50%;
    width: 1px;
    height: 1px;
    margin-left: -125px;
    appearance: none;
    opacity: 0;
}