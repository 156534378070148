.Popular {
    grid-area: popular;
    padding-bottom: 5rem;
}

.Popular h2,
.Popular p {
    text-align: center;
}

.Popular h2 {
    font-size: 1.2rem;
}

.Popular p {
    max-width: 550px;
    font-size: 0.9rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.5rem;
    margin-bottom: 2.5rem;
  }

  .Popular ul {
    display: grid;
    grid-template-rows:
      min-content
      min-content;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 2rem 1.5rem;
    margin: 0;
    padding: 0;
  } 

  .Popular  li {
    list-style: none;
  }

  .Popular li  a {
    font-size: 1rem;
    color: rgb(11,121,186);
    @media (prefers-color-scheme: dark) {
      color: white;
    }
  }

  @media screen and (min-width: 500px) {
    .Popular h2 {font-size: calc( 1.2rem + (25.6 - 19.2) * (100vw - 500px) / (1200 - 500)); }
  }
  @media screen and (min-width: 1200px) {
    .Popular h2 {  font-size: 1.6rem; }
  }