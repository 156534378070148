.AgentsLogoContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-bottom: 1rem;
}

.AgentsLogo {
    width: 150px;
    margin-bottom: 2rem;
}

.FormTitle {
    font-size: 22.6px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 1rem;
}

.PropertyContainer {
    padding: 1rem;
    border: 1px solid red;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 2rem;
}

.PropertyContainer div:first-of-type {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.PropertyContainer div:nth-of-type(2) {
    font-size: 14.4px;
}