.InlineWrapper {
    font-size: 0.8rem;
    color: #fff;

}

.InlineWrapper span:first-of-type {
    padding-right: 0.5rem;
}

.InlineWrapper a {
    color: #fff;
}