.UpgradeWrapper {
    height: 100%;
    width: 100%;
    padding-bottom: 1rem;
}


.upgradeContain {
    background-color: #F2F2F2;
    padding: 2rem;
    border-radius: 10px;
    text-align: left;

    @media (prefers-color-scheme: dark) {
        background-color: unset;
    }
}

.upgradeContain h2 {
    text-align: left;
}

.upgradeContain p {
    margin-bottom: 0;

}

.buttonContainerContact {
    margin-top: 1rem;
    display: block
}

.smallButton {
    cursor: pointer;
    transition: color 500ms;
    border: none;
    padding: 0.5rem 1.5rem;
    border-radius: 10px;
    font-size: 14.4px;
    border: none;
    background-color: #941E3A;
    color: white;
    text-align: center;
}

.smallButton:hover {
    background: #F73261;
}

.GridContainer {
    border: solid 1px lightgray;
    border-radius: 15px;
    width: 100%;
}

.flexTitle {
    display: flex;
}

.flexTitle span {
    margin-top: 2px;
}

.flexTitle img {
    padding-right: 5px;
}

.marginBottom {
    margin-bottom: 1rem;
}

.gridRow {
    padding: 1rem;
    width: 100%;
    max-width: 100%;

}

.gridRow h2 {
    text-align: center;
    margin: 0 !important;
}

.gridRow:nth-of-type(2) {
    border-top: solid 1px lightgray;
}

.contentRow {

    padding: 0rem 1rem;
    width: 100%;
    max-width: 100%;

}

.contentRow h2 {
    text-align: center;
    margin: 0;
}

.contentRow:nth-of-type(2) {
    border-top: solid 1px lightgray;
}

.agentSlideContainer {
    width: 100%;
}

.companyPadder {
    padding: 0 0 0rem 0;
}

.agentList {
    max-height: 300px;
    overflow-y: hidden;
    display: grid;
    grid-template-columns: 1fr;
    scrollbar-width: none;
    -ms-overflow-style: none;
    transition:height 500ms;

}

.agentList::-webkit-scrollbar {
    display: none;
}

.open {
    height: auto;
}

.close {
    height: 0px;
}

.companyContainer {
    width: 100%;
    display: grid;
    grid-template-rows: 50px;
    grid-gap: 1rem;
    padding: 0.5rem 0;
}

@media screen and (min-width: 296px) {
    .companyContainer {  grid-template-columns: 60px 1fr 12px; }
}

.contactable {
    cursor: pointer;
}

.companyLogo {
    height: 100%;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.companyLogo img {
    max-width: 100%;
    max-height: 100%;
}

.plusContainer {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 296px) {
    .plusContainer {  display: none; }
}


.agentDetails {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.agentDetails p:first-of-type {
    font-weight: bold;
}

.agentContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 50px;
    grid-gap: 1rem;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.callButton {
    background: #941E3A;
    transition: color 500ms;
    color: white;
    border: none;
    padding: 0.5rem;
    border-radius: 10px;
    font-size: 15px;
    width: 90px;
    font-family: proxima-nova, sans-serif;
    cursor: pointer;
}

.callButton :hover {
    background: #F73261;

}


.AgentDetailsContainer {
    padding-bottom: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    width: 100%;
}

@media screen and (min-width: 768px) {
    .AgentDetailsContainer {  padding: 0 0 0 0rem; }
}

.AgentDetailsContainer h2 {
    margin: 0 !important;
}

.swiper {
    padding-bottom: 30px;
}

.swiper-pagination-bullet-active {
    background-color: #ff5939;
}