.MenuButton {
    position: fixed;
    top: 70px;
    width: 100%;
    height: 2.5rem;
    right: 0;
    z-index: 9999;
    background-image: linear-gradient(90deg, rgb(232, 51, 107) 0%, rgb(235, 94, 64) 100%);
    color: white;
    border: 0;
    font-size: 1.1rem;
    text-decoration: underline;
    cursor: pointer;
}

.wrapper {
    width: 100%;
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}


@media (min-width: 1200px) {
    .wrapper {
        padding: 0;
    }
}

@media screen and (min-width: 768px) {
    .MenuButton { top: 100px; }
}

@media screen and (min-width: 968px) {
    .MenuButton { display: none; }
}

.Intro {
    padding: 3em 0;
    text-align: center;
    display: flex;
    flex-wrap: wrap;

   
}

@media screen and (max-width: 768px) {
    .Intro {  padding: 3em 1em; }
}

.Intro h1,
.Intro h2 {
    font-weight: bold;
    font-size: 30px;
    margin: 0 auto 0.75em auto;
    flex-basis: 100%;
}

.Intro p {
    font-size: 18px;
    margin: auto;
    max-width: 600px;
}

.TownsWrapper {
    margin-bottom: 50px;
}

.TownRow {}

.TownRow h2 {
    padding: 30px 0;
}

.TownRow div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 0.5rem 1rem;
}

.TownRow a {
    color: #5d2929;

    @media (prefers-color-scheme: dark) {
        color: rgb(235, 235, 235);
    }
}

.TownRow a:hover {
    color: #ea4958;
}