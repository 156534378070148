.FormContainer {
    grid-area: form;
    position: relative;
    border: 1px solid rgb(232, 51, 107);
    border-radius: 5px;
}

.FormInnerContainer {
    padding: 1rem;
}

.FormInnerContainer h2 {
    font-size: 26px;
    margin-bottom: 15px;
    text-align: center;
}

.alertDanger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.TextInputLabel {
    color: #666766;
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.reducedMargin {
    margin-bottom: 10px;
}

.TextInputElement {
    border-radius: 5px;
    border: solid 1px #666766;
    margin-top: 0.5rem;
    padding: 0.7rem;
}


.SinglePropertyFormGrid button {
    width: 100%;
}

.checkbox:checked:before {
    border: #1C4CBD;
    background-color: #1C4CBD;
}

.TextDropDownElement {
    border-radius: 5px;
    border: solid 1px #666766;
    background: none;
    appearance: auto;
    margin-top: 0.5rem;
    height: 40px;
    font-size: 13px;
}

.TextAppendElement {
    background: #eee;
    border: solid 1px #666766;
    padding: 0.5em 1em;
    margin-top: 0.5rem;
    font-weight: bold;
    color: black;
    font-size: 13px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: none;
}

.TextAppendElement span {
    min-height: 23px;
    display: inline-flex;
    align-items: center;
}

.InputGroup {
    display: flex;
    align-content: stretch;
}

.InputGroup input {
    flex: 1 0 auto;
    width: 5%;
    border-radius: 0px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: none;
}

.InputTickBox {
    display: inline-block;
    float: left;
    margin-top: 5px;
    vertical-align: middle;
    box-sizing: border-box;
    padding: 0;
}

.InputTickBoxLabel {
    color: #666766;
    margin-bottom: 15px;
    font-size: 12px;
    display: block;
    margin-left: 25px;
    padding-top: 3px;


}

.InputTickBoxLabel a {
    text-decoration: underline;
}

.InputTickBoxLabel :checked:before {
    background-color: green;
}


.ThumbsContainer {
    display: flex;
    flex-flow: row wrap;
    margin-top: 16px;
}

.ThumbImage {
    display: block;
    width: auto;
    height: 100%;
    object-fit: contain;
}

.ThumbInner {
    display: flex;
    min-width: 0px;
    overflow: hidden;
}

.Thumb {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid rgb(234, 234, 234);
    margin-bottom: 8px;
    margin-right: 8px;
    width: 100px;
    height: 100px;
    padding: 4px;
    box-sizing: border-box;
    position: relative;
}

.DropzoneContainer {
    padding: 20px;
    border-width: 2px;
    border-radius: 8px;
    border-color: #eeeeee;
    border-style: dashed;
    color: #bdbdbd;
    margin-bottom: 15px;
    text-align: center;
    font-weight: bold;
}

.DropzoneContainer img {
    width: 10%;
}

.ThumbButton {
    right: -5px;
    position: absolute;
    width: unset !important;
    color: white;
    background-color: black;
    border-radius: 50%;
    top: -4px;
    cursor: pointer;
}

.TextInputDesc {
    color: #666766;
    margin-bottom: 15px;
    font-size: 12px;
    margin-top: 0px;
}

.TextAreaInputElement {
    border-radius: 5px;
    border: solid 1px #666766;
    margin-top: 0.5rem;
    padding: 0.7rem;
    height: 70px;
}

@media screen and (min-width: 768px) {
    .TwoColumnGrid {

        display: flex;
    }

    .TwoColumnGrid label:first-child {
        padding-right: 10px;
    }

    .TwoColumnGrid label {
        flex: 50%;
    }
}