.UpgradeButton {
    transition: background-color 500ms, color 500ms;
    text-align:center;
    border:none;
    padding:1rem;
    border-radius:10px;
    border:1px solid #941E3A;
    color: #941E3A;
    font-weight: bold;
    font-size:18px;
    margin-top:2rem;
    cursor:pointer;
    
}

.UpgradeButton:hover {
    border:1px solid #F73261;
    background-color: #F73261;
    color:white;
}

.UpgradeContain {
    background-color:#F2F2F2;
    padding:2rem;
    border-radius:10px;
   
    @media (prefers-color-scheme: dark) {
        background-color:unset;
    }
}

.UpgradeContain h2{
    text-align:center;
}
.UpgradeContain p{
    margin-bottom:1rem;
}

.UpgradeWrapper {
    height:100%;
    width:100%;
    padding: 1rem 0;
}