.Container {
    text-align: center;
    position: relative;
    background-color: rgb(250, 250, 250);
}

.swiperSlide {
    position: relative  !important;;
    height: 60vh !important;
    margin: 0;
    padding: 0;
    margin-bottom: -5px;
}

.swiper-pagination {
   
}

.swiper-pagination-bullet {
   

}

.swiper-pagination-bullet-active {
    height: 1rem;
}

.swiper-pagination-bullet:hover,
.slick-active {
    height: 1rem;
}


/*.swiperContainer > :nth-child(2) {
    top: auto;
    bottom: 3rem;
    align-items: flex-end;
    background-color: inherit;
    height: 0;
}

.swiperContainer :nth-child(2) span {
    position: relative;
    width: 2.5rem;
    height: 0.2rem;
    margin: 0 0.5rem;
    
    border-radius: 0;
    opacity: 1;
    transition: height 0.5s;
    background-color: inherit;
}*/

.swiperContainer :nth-child(2) span:hover {
    height: 1rem;
}