.AvailibilityWrapper {
    height: 100%;
    width: 100%;
    display: grid;
    padding: 2rem 0;
    border-bottom: 2px solid #BBBBBB;
}

.AvailibilityWrapper td {
    font-size: 16px;
}

.AvailibilityWrapper tbody tr:nth-child(odd) {
    background-color: #F2F2F2;

    @media (prefers-color-scheme: dark) {
        background: rgb(29, 37, 44);
        color: #F2F2F2 !important;
    }

}

.AvailibilityWrapper tbody tr td {
    @media (prefers-color-scheme: dark) {
        color: #F2F2F2;
    }
}

.AvailibilityWrapper tbody tr:hover {
    background-color: #dedcdc !important;

}

.AvailableProps {
    cursor: pointer;
}

.pagination,
.pagination svg {
    @media (prefers-color-scheme: dark) {
        color: #F2F2F2 !important;
    }
}

.AvailibilityWrapper tbody tr:hover td {
    @media (prefers-color-scheme: dark) {
        color: black;
    }
}

.availabilityStatus {
    padding: 0.5rem 1rem;
    width: min-content;
    white-space: nowrap;
    border-radius: 25px;
    background-color: #941E3A;
    color: white;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: normal;
}


@media screen and (max-width: 768px) {
    .size {
        display: none !important;
    }
}

@media screen and (min-width: 768px) {
    .AvailibilityWrapper tbody tr td {
        width: 250px;
    }

    .AvailableProps td:nth-child(1) {
        width: 100px;
    }
}