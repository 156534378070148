.ContactUsFormGrid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    position: relative;
    transition: opacity 500ms;
    margin: auto;

    @media (min-width: 576px) {
        grid-template-columns: 1fr 1fr;
        width: 500px;
    }

    @media (min-width: 768px) {
        width: 600px;

    }
}

.FormWrapper {
    position: relative;
}

.formSuccess {
    opacity: 0.1;
    pointer-events: none;
}

.SuccessContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SuccessMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: solid 1px #666766;
    background-color: white;
    border-radius: 10px;
    margin-bottom: 4rem;
    padding: 1rem;
    transition: opacity 500ms;
    opacity: 0;
}

.SuccessMessageShow {
    opacity: 1;
    z-index: 1000;
}

.SuccessMessage div {
    font-size: 22.6px;
    font-weight: bold;
}

.SuccessMessage p {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 2rem;
}

.TextInputElement {
    border-radius: 5px;
    border: solid 1px hsl(0, 0%, 80%);
    margin-top: 0.5rem;
    padding: 0.7rem;
    font-family: proxima-nova, sans-serif;

    @media (prefers-color-scheme: dark) {
        background-color: unset;
        color: white;
    }
}

.TextAreaInputElement {
    border-radius: 5px !important;
    border: solid 1px hsl(0, 0%, 80%) !important;
    margin-top: 0.7rem !important;
    padding: 0.7rem;
    resize: none;
    height: 90px;

    @media (prefers-color-scheme: dark) {
        background-color: unset !important;
        color: white;
    }
}

.TextInputLabel {
    color: #666766;
    text-align: left;
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (prefers-color-scheme: dark) {
        color: white;
    }
}

.MessageContainer {
    @media (min-width: 576px) {
        grid-column-start: 1;
        grid-column-end: 3;
    }
}

.ButtonContainer {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 576px) {
        grid-column-start: 1;
        grid-column-end: 3;
    }
}

.FancyButton {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5px;
    color: white;
    background: rgb(247, 50, 97);
    background: linear-gradient(90deg, rgba(247, 50, 97, 1) 0%, rgba(255, 89, 57, 1) 100%);
    cursor: pointer;
    width: 80%;
    font-weight: bold;
    font-size: 18px;
}

.FancyButton:hover {
    background: linear-gradient(180deg, rgba(247, 50, 97, 1) 0%, rgba(255, 89, 57, 1) 100%);
}

.Alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.AlertDanger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.AlertSuccess {
    color: #fff;
    background-color: green;
    border-color: green;
}

.RadioButtonElement {
    border-radius: 5px;
    border: solid 1px #666766;
    margin-top: 0.5rem;
    padding: 0.7rem;
}

.RadioButtonContainer {
    margin-top: 0.5rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
}

.mediumText {
    font-size: 14px;
}

.RadioIcon {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: white;
}

.RadioIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    margin-right: 1rem;
    border-radius: 50%;
    background-color: gray;
}

.RadioButton {
    border-radius: 30px;
    border: solid 1px lightgray;
    min-width: 150px;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    transition: color 500ms, background-color 500ms, border 500ms;
    white-space: nowrap;
}

.activeBasic {
    color: #941E3A;
    border: solid 1px #941E3A;
}

.activeIcon {
    border-radius: 5px;
    background-color: white;
    border: solid 1px #941E3A;
    color: #941E3A;

    @media (prefers-color-scheme: dark) {
        background-color: unset;
    }

    /* ${RadioIconContainer}{
        background-color: #941E3A;
        
      }
      ${RadioIcon}{
        width:8px;
        height:8px;
        
      }*/
}

.icon {
    background-color: unset;
    border-radius: 5px;
}

.basic {
    background-color: unset;
}

.RadioButton:hover {
    border: solid 1px #941E3A;
    color: #941E3A;
}

.RadioButton:hover div:first-of-type {
    border: solid 1px #941E3A;
}

.SelectOption {
    display: grid;
    grid-template-columns: 20px 1fr;
    grid-gap: 1rem;

}

.SelectOption img {
    width: 100%;
}

.SelectBox {
    border-radius: 5px !important;
    border: solid 1px hsl(0, 0%, 80%) !important;
    margin-top: 0.5rem !important;
    padding: 0.7rem !important;
    font-family: proxima-nova, sans-serif !important;
    background: white url(https://neo.completelyretail.co.uk/icons/simplearrow-down-grey.svg) 98%/2% no-repeat;
}

@media screen and (min-width: 768px) {
    .RadioButtonContainer {
        grid-template-columns: min-content min-content min-content;
    }

    .mediumText {
        font-size: 16px;
    }
   
}

.SelectBox::after {
    background: red;
}

.activeCircle {
    background-color: #941E3A;
}