.AlertDanger {
    color: #721c24;
    background-color: #f8d7da;
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid #f5c6cb;
    border-radius: 0.25rem;
}

.ButtonContainer {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.ContactUsFormGrid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    width: 250px;
    position: relative;
    transition: opacity 500ms;

    @media (min-width: 576px) {
        width: 500px;
    }

    @media (min-width: 768px) {
        width: 600px;
    }
}

.FancyButton {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5px;
    color: white;
    background: rgb(247, 50, 97);
    background: linear-gradient(90deg, rgba(247, 50, 97, 1) 0%, rgba(255, 89, 57, 1) 100%);
    cursor: pointer;
    width: 80%;
    font-weight: bold;
    font-size: 18px;

}

.FancyButton :hover {
    background: linear-gradient(180deg, rgba(247, 50, 97, 1) 0%, rgba(255, 89, 57, 1) 100%);
}


.FormWrapper {
    position: relative;
}

.FormWrapperSuccess form {
    opacity: 0.1;
    pointer-events: none;
}

.MessageContainer {
    width: 100%;
}

.PropertyContainer {
    padding: 1rem;
    border: 1px solid red;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 2rem;
}

.PropertyContainer div:first-of-type {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.PropertyContainer div:nth-of-type(2) {
    font-size: 14.4px;
}

.SuccessContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SuccessMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: solid 1px #666766;
    background-color: white;
    border-radius: 10px;
    margin-bottom: 4rem;
    padding: 1rem;
    transition: opacity 500ms;
    opacity: 0;
}

.SuccessMessageShow {
    opacity: 1;
    z-index: 1000;
}

.SuccessMessageShow div {
    font-size: 22.6px;
    font-weight: bold;
}


.SuccessMessageShow p {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 2rem;
}