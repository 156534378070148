.ButtonContainer {
    margin-top: 1rem;
    display: block;
}

.smallButton {
    cursor: pointer;
    transition: color 500ms;
    border: none;
    padding: 0.5rem 1.5rem;
    border-radius: 10px;
    border: none;
    background-color: #941E3A;
    color: white;
    text-align: center;
}

.smallButton:hover {
    background: #F73261;
}

@media screen and (max-width: 768px) {
    .topMarging {
        margin-top: 2rem;
    }
}