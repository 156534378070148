.FooterFluid {
  background-color: #1d252c;
  color: white;
  padding: 2.5rem 0 1.5rem;
}

.primaryWrapper {
  width: 100%;
  max-width: 1140px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5rem;
  grid-template-areas:
    "links"
    "contacts"
    "group";
}

.primaryWrapper a {
  display: block;
  color: white;
  font-size: 14px;
  padding-bottom: 8px;
}

.footerInfo {
  grid-area: info;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-template-rows: min-content;
  grid-gap: 0.5rem;
}

.footerInfoLink {
  display: none;
  overflow: hidden;
}

.footerInforSocial {
  display: grid;
  justify-content: flex-start;
  grid-auto-flow: column;
  grid-gap: 20px;
  padding: 0.5rem 0.25rem;
  margin: auto 0;
}



.footerInfoSocailLink {
  width: 100%;
  max-width: 35px;
  max-height: 35px;
}

.footerlinks {
  grid-area: links;
  font-size: 0.8rem;
}

.isOpen img {
  transform: rotateZ(180deg) !important;
}

.footerLinksHeading span {
  position: absolute;
  right: 0.25rem;
  top: 50%;
  display: block;
  width: 26px;
  height: 26px;
  padding: 6px;
  margin-top: -13px;
  background: linear-gradient(90deg, #e8336b 0%, #eb5e40 100%);
  border-radius: 50%;
}

.footerLinksHeading span img {
  display: block;
  height: 14px;
  transform: rotateZ(90deg);
  margin: 0 auto;
}

.footerLinksHeading {
  position: relative;
  margin: 0;
  padding: 0.5rem 0.25rem;
  font-size: 16px;
  cursor: pointer;
}

.expandable a {
  margin: 0.5rem 0 0;
}

.expandable {
  padding-left: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}


.expandable a:first-of-type {
  margin: 0;
}

.footerContacts {
  grid-area: contacts;
}

.footerContacts a {
  text-decoration: none;
}


.footerGroup {
  grid-area: group;
}

.footerHref {
  cursor: pointer;
  font-size: 0.8rem;
}

.secondaryWrapper {
  width: 100%;
  max-width: 1140px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows:
    min-content min-content;
  grid-template-areas:
    "nav"
    "message";
  align-items: center;
  align-content: center;
  grid-gap: 1rem;
  justify-items: center;
  padding-top: 1rem;
  justify-content: center;
  border-top: 2px solid rgba(255, 255, 255, 0.25);
}

.secondaryWrapper a {
  color: white;
}

.footerPowered {
  grid-area: message;
  margin: 0;
  text-align: left;
  font-size: 0.8rem;
  justify-self: flex-start;
}

.footerNav {
  grid-area: nav;
}

.footerNav a {
  display: inline-block;
  text-align: center;
  font-size: 0.8rem;
}

.footerNav a:not(:last-of-type) {
  margin-right: 0.75rem;
}

.footerFakeRef {
  margin-right: 0.75rem;
  cursor: pointer;
  font-size: 0.8rem;
}

.tertiaryWrapper {
  margin-top: 1rem;
  text-align: right;
  width: 100%;
  max-width: 1140px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.PopularWrapper {
  background-color: #fafafa;
  width: 100%;
  margin: auto;
  padding: 0;
  position: relative;

  @media (prefers-color-scheme: dark) {
    background-color: unset;
  }
}

.wrapperPopular {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 50px;
}

@media screen and (max-width: 768px) { 
  .expandable {
    overflow: hidden;
    height: 0;
  }
}

@media screen and (min-width: 768px) {
  .FooterFluid {
    padding: 40px 0;
  }

  .primaryWrapper {
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "info info"
      "links contacts";
    margin-bottom: 1rem;
  }

  .footerInfo {
    grid-template-columns: 180px max-content;
    grid-template-rows: min-content;
    grid-gap: 2rem;
  }

  .footerLinksHeading {
    font-weight: bold;
    cursor: default;
  }

  .footerLinksHeading span {

    display: none;
  }


  .footerInfoLink {
    display: block;
    width: 100%;
    max-width: 180px;
  }

  .footerInforSocial {
    padding: 0;
  }

  .footerContacts div {
    display: grid;
    grid-template-columns: max-content max-content;
    column-gap: 3rem;
    grid-template-rows: min-content min-content min-content;
  }

  .footerContacts a:nth-of-type(2) {
    margin: 0;
  }

  .secondaryWrapper {
    grid-template-columns: 1fr 1rem max-content;
    grid-template-rows: min-content;
    grid-template-areas: "message . nav";
  }

  .wrapperPopular {
    max-width: 720px;
  }
}

@media screen and (min-width: 576px) {
  .wrapperPopular {
    max-width: 540px;
  }
}

@media screen and (min-width: 992px) {
  .wrapperPopular {
    max-width: 960px;
  }
}


@media screen and (min-width: 1200px) {
  .footerInfo {
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content;
    grid-gap: 0.5rem;
  }

  .primaryWrapper {
    grid-gap: 0.5rem;
    grid-template-columns: 180px 200px 180px 440px;
    grid-template-areas: "info . links contacts";
  }

  .wrapperPopular {
    max-width: 1145px;
    padding-right: 0;
  }
}