.Container {
    position: relative;
    z-index: 1030;
    height: 70px;
}

.homeContainer {
    height: 70px;
}

.betaLogo {
    height: 50px;
}


.hideMobile {
    display: none;
}

.buttonContainer {
    display: flex;
    gap: 10px;
}

.accountButton {
    background: white !important;
    color: #941E3A !important;


    @media (prefers-color-scheme: dark) {
        color: white;
        background: #941E3A;
    }
}

.accountButton span:hover {
    color: #941E3A;

    @media (prefers-color-scheme: dark) {
        color: white;
    }
}

.bar {
    position: relative;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
}

.link {
    color: white;
    padding-bottom: 3px;
}

.link:hover {
    border-bottom: solid #F73261;
    border-bottom-width: 3px;
    padding-bottom: 0;
}

.advertise:hover .hide {
    display: flex;
}

.advertise:hover .link {
    border-bottom: solid #F73261;
    border-bottom-width: 3px;
    padding-bottom: 0;
}


.NavContainer {
    display: flex;
    justify-content: space-between;
}

.advertiseDropdown {
    position: absolute;
    justify-items: flex-start;
    z-index: 1000;
    display: none;
}

.advertiseInner {
    padding: 0.5rem;
    font-size: 1rem;
    text-align: left;
    list-style: none;
    background: #FFFFFF;
    border-radius: 10px;
    border: 3px solid #FFFFFF;
    flex-direction: column;
    width: 172px;
    display: flex;
    margin-top: 1rem;

    @media (prefers-color-scheme: dark) {
        color: white;
        background: rgb(29, 37, 44);
        border: 3px solid rgb(29, 37, 44);
    }
}

.advertiseDropdown a {
    color: black;
    padding: 5px;
    font-weight: 700;

    @media (prefers-color-scheme: dark) {
        color: white;
    }
}

.advertiseDropdown a:hover {
    background: #F4F4F4;
    border-radius: 10px;

    @media (prefers-color-scheme: dark) {
        color: black;
    }
}

.advertiseLink {
    margin: 0;
    cursor: pointer;
}

.advertise {
    position: relative;
}

.advertiseArrow::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: 83%;
    border: solid #FFFFFF;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 4px;
    margin-bottom: 3px;
    margin-left: 6px;
    -webkit-transform: rotate(-135deg) !important;
    -ms-transform: rotate(-135deg) !important;
    transform: rotate(-135deg) !important;
    -webkit-transform: rotate(-135deg) !important;
    background-color: #FFFFFF;

    @media (prefers-color-scheme: dark) {
        border: solid rgb(29, 37, 44);
        background: rgb(29, 37, 44);
    }
}

.InnerUl {
    width: 100%;
    list-style: none;
    margin-top: 0;
    width: 100%;
    max-width: 1140px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    height: 100%;
    height: 75px;
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0;
}

@media screen and (max-width: 1147px) {
    .InnerUl {
        padding-right: 15px;
        padding-left: 15px;
    }
}

@media screen and (max-width: 768px) {
    #cr-navbar-menu {

        display: none;
    }

    .NavContainer {
        display: none;
    }

    .buttonContainer {
        display: none;
    }

}

#cr-navbar-menu {
    width: 200px;
    text-align: end;
}

.InnerUl>a {
    color: #fff;
    flex-basis: min-content;
    text-align: center;
    font-size: 0.9rem;
    letter-spacing: 1px;
    background-color: transparent;
    border: none;
    font-family: proxima-nova, sans-serif;
    padding: 0;
    cursor: pointer;
    flex-basis: inherit;
    text-align: flex-start;
}

.extraWidth {
    max-width: 1500px;
    padding: 0 1rem
}

.middleNav {
    width: 40%;
}

.logoLink {
    height: 50%;
    min-height: 50px;
    width: 100%;
    max-width: 215px;
    padding: 0;
    margin: 0;
    display: block;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

@media screen and (min-width: 900px) {

    @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
        .logoLink {
            width: 50px;
            overflow: hidden;
        }
    }
}

.logoLink svg {
    height: 100%;
}


.mobileMenuOpenButton div {
    width: 35px;
    height: 5px;
    background-color: white;
    margin: 6px 0;
}

.mobileMenuCloseButton {
    color: white;
    font-weight: bold;
    font-size: 30px;
    margin-right: 10px;
    margin-bottom: 10px;
}


.mainBullet {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 20px;
}

.show {
    display: block;
}

.advertise ul {
    margin-top: 10px;
    padding-left: 13px;

}

.advertise li {
    margin: 10px 0;
}

.InnerMobile ul {
    list-style: none;
}

.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-bottom: 3px;
    margin-left: 6px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);

    @media (prefers-color-scheme: dark) {
        border: solid rgb(235, 235, 235);
        border-width: 0 3px 3px 0;
    }
}

.arrowUp {
    transform: rotate(-135deg) !important;
    -webkit-transform: rotate(-135deg) !important;
    margin-bottom: 0px;
}

.InnerMobile {
    background: white;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 75px;
    left: 0;
    background-color: rgba(255, 255, 255, 0.95);

    @media (prefers-color-scheme: dark) {
        background: rgb(29, 37, 44);
        color: rgb(235, 235, 235);
    }
}



.subBullet {
    margin-bottom: 20px;

}

.subBullet ul {
    padding-left: 17px;
}

.subBullet li {
    margin-bottom: 15px;
    font-size: 18px;


}

.subBullet li img {
    margin-right: 9px;
    width: 20px;
}

.subBullet li a {
    display: inline-flex;
}

@media screen and (min-width: 768px) {
    .homeContainer {
        height: 100px;
    }

    .InnerMobile {
        display: none;
    }

    .mobileMenuOpenButton {
        display: none;
    }

    .mobileMenuCloseButton {
        display: none;
    }
}