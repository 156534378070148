.Background {
    background-image: linear-gradient(90deg, rgb(232, 51, 107) 0%, rgb(235, 94, 64) 100%)
}

.Container {
    text-align: center;
    padding: 0 0.75rem;
    width: 100%;
    max-width: 1140px;
    margin-right: auto;
    margin-left: auto;
}

.Container ul {
    display: grid;
    justify-content: center;
    margin: 0;
    padding: 0;
    grid-template-columns: repeat(26, 1fr);
    grid-template-rows: min-content;
}

.Container ul li {
    list-style: none;
    margin: 0;
    color: #ffffff;
}

.Container ul li a,
.Container ul li p {
    display: block;
    text-align: center;
    margin: 0;
    color: #ffffff;
    padding: 0.75rem 0;
}

.Container ul li a {
    cursor: pointer;
}

.Container ul li p {
    opacity: 0.5;
}

.Container ul li:first-child {
    border-left: none;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.SideMenu {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: calc(70px + 3.5rem);
    z-index: 550;
}

@media screen and (min-width: 768px) {
    .SideMenu {
        top: calc(100px + 3.5rem);
    }
}

.SideMenu ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 0;
    min-width: 200px;
    max-width: 90vw;

    background-image: linear-gradient(90deg, rgb(232, 51, 107) 0%, rgb(235, 94, 64) 100%);
    color: white;
    padding: 0.25rem 1rem;
}

.SideMenu ul li {
    list-style: none;
    margin: 0;
}

.SideMenu ul li a,
.SideMenu ul li p {
    display: block;
    padding: 0.75rem 0;
    text-align: center;
    color: white;
    margin: 0;
}

.SideMenu ul li a {
    cursor: pointer;
}

.SideMenu ul li p {
    opacity: 0.5;
}